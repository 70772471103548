import request from 'utils/request';

const api = {
  OFFER_DETAIL: '/api/offer/detail',
  UPDATE_OFFER_SOURCE: '/api/offer/updateOfferSource',
  UPDATE_OFFER_DESCRIPTION: '/api/offer/updateOfferDescription',
  UPDATE_OFFER_PAYOUT: '/api/offer/updateOfferPayout',
  UPDATE_OFFER_SETTING: '/api/offer/updateOfferSetting',
  UPDATE_OFFER_ASYNC_INFO: '/api/offer/updateOfferAsyncInfo',
  UPDATE_OFFER_SMART_STATUS: '/api/offer/updateSmartStatus',
  // aff blacklist
  AFF_BLACKLIST: '/api/offer/{offerId}/affBlack/list',
  ADD_AFF_BLACKLIST: '/api/offer/{offerId}/affBlack/add',
  DELETE_AFF_BLACKLIST: '/api/offer/{offerId}/affBlack/delete',
  // aff sub blacklist
  AFF_SUB_BLACKLIST: '/api/offer/{offerId}/affSubBlack/list',
  DELETE_AFF_SUB_BLACKLIST: '/api/offer/{offerId}/affSubBlack/delete',
  BATCH_DELETE_AFF_SUB_BLACKLIST: '/api/offer/{offerId}/affSubBlack/batch/delete',
  // source aff blacklist
  SOURCE_AFF_BLACKLIST: '/api/offer/{offerId}/sourceAffBlack/list',
  // source aff blacklist
  SOURCE_AFF_SUB_BLACKLIST: '/api/offer/{offerId}/sourceAffSubBlack/list',
  // offer aff white list
  OFFER_AFF_SUB_WHITE_LIST: '/api/offer/{offerId}/offerAffSubWhite/list',
  ADD_OFFER_AFF_SUB_WHITE_LIST: '/api/offer/{offerId}/offerAffSubWhite/add',
  DELETE_OFFER_AFF_SUB_WHITE_LIST: '/api/offer/{offerId}/offerAffSubWhite/delete',
  BATCH_DELETE_OFFER_AFF_SUB_WHITE_LIST: '/api/offer/{offerId}/offerAffSubWhite/deleteIds',

  // offer track cap
  AFF_CLICK_CAP_LIST: '/api/offer/{offerId}/affClickCap/list',
  ADD_AFF_CLICK_CAP: '/api/offer/{offerId}/affClickCap/add',
  DELETE_AFF_CLICK_CAP: '/api/offer/{offerId}/affClickCap/delete',

  // offer aff cap
  AFF_CAP_LIST: '/api/offer/{offerId}/affCap/list',

  // offer aff permit
  OFFER_PERMIT_LIST: '/api/offer/{offerId}/permit/aff/list',
  ADD_OFFER_PERMIT: '/api/offer/{offerId}/permit/aff/add',
  DELETE_OFFER_PERMIT: '/api/offer/{offerId}/permit/aff/delete',

  // offer status log
  STATUS_LOG: '/api/offer/{offerId}/status/log',

  // offer timed task
  UPDATE_TIMED_TASK: '/api/offer/addOfferSetting',

  // offer  campaign name
  CHANGE_CAMPAOGN_NAME: '/api/offer/addOrUpdate/campaignNameSetting',

  // Event Revenue
  EVENT_REVENUE_LIST: '/api/offer/detail/eventRevenue/list',
  UPDATE_EVENT_REVENUE: '/api/offer/detail/eventRevenue/addOrUpdate',
  DELETE_EVENT_REVENUE: 'api/offer/detail/eventRevenue/delete',

  // Offer Affiliate Whitelist
  OFFER_AFF_WHITELIST: '/api/offer/{offerId}/affWhite/list',
  ADD_OFFER_AFF_WHITELIST: '/api/offer/{offerId}/affWhite/add',
  DELETE_OFFER_AFF_WHITELIST: '/api/offer/{offerId}/affWhite/delete',
  BATCH_UPDATE_OFFER_AFF_WHITELIST: '/api/offer/affWhite/update',

  // Source Offer Clone
  SOURCE_CLONE_LIST: '/api/offer/detail/sourceClone/list',
  ADD_SOURCE_CLONE: '/api/offer/detail/sourceClone/add',
  DELETE_SOURCE_CLONE: '/api/offer/detail/sourceClone/delete',

  // Affiliate Payout Cap limit
  AFFILIATE_PAYOUT_CAP_LIST: '/api/offer/{offerId}/affPayoutCap/list',
  ADD_AFFILIATE_PAYOUT_CAP: '/api/offer/{offerId}/affPayoutCap/addOrUpdate',
  DELETE_AFFILIATE_PAYOUT_CAP: '/api/offer/{offerId}/affPayoutCap/delete',

  // Offer Source Trackinges
  OFFER_SOURCE_TRACK_LIST: '/api/offer/{offerId}/offerSourceTrack/list',
  CHANGE_OFFER_SOURCE_TRACK: '/api/offer/{offerId}/offerSourceTrack/addOrUpdate',
};

export function getOfferDetail(offerId) {
  return request({
    url: api.OFFER_DETAIL,
    method: 'get',
    params: {
      offerId,
    },
  });
}

export function updateOfferSource(data) {
  return request({
    url: api.UPDATE_OFFER_SOURCE,
    method: 'put',
    data,
  });
}

export function updateOfferDescription(data) {
  return request({
    url: api.UPDATE_OFFER_DESCRIPTION,
    method: 'put',
    data,
  });
}

export function updateOfferPayout(data) {
  return request({
    url: api.UPDATE_OFFER_PAYOUT,
    method: 'put',
    data,
  });
}

export function updateOfferSetting(data) {
  return request({
    url: api.UPDATE_OFFER_SETTING,
    method: 'put',
    data,
  });
}

export function updateOfferAsyncInfo(data) {
  return request({
    url: api.UPDATE_OFFER_ASYNC_INFO,
    method: 'put',
    data,
  });
}

export function updateOfferSmartStatusApi(data) {
  return request({
    url: api.UPDATE_OFFER_SMART_STATUS,
    method: 'put',
    data,
  });
}

// aff blacklist
export function getAffBlacklist(offerId, query) {
  const url = api.AFF_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function deleteAffBlacklist(offerId, affiliateIds) {
  const url = api.DELETE_AFF_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      affiliateIds,
    },
  });
}

export function getAffSubBlacklist(offerId, query) {
  const url = api.AFF_SUB_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function deleteAffSubBlacklist(offerId, affiliateIds) {
  const url = api.DELETE_AFF_SUB_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      affiliateIds,
    },
  });
}

export function batchDelAffSubBlacklist(offerId, ids) {
  const url = api.BATCH_DELETE_AFF_SUB_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

// aff blacklist & aff sub blacklist
export function addAffBlacklist(offerId, data) {
  const url = api.ADD_AFF_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

// Source Aff blacklist
export function getSourceAffBlacklist(offerId, params) {
  const url = api.SOURCE_AFF_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params,
  });
}

// Source Aff Sub blacklist
export function getSourceAffSubBlacklist(offerId, query) {
  const url = api.SOURCE_AFF_SUB_BLACKLIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

// offer Aff Sub white list
export function getOfferAffSubWhiteList(offerId, query) {
  const url = api.OFFER_AFF_SUB_WHITE_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function addOfferAffSubWhiteList(offerId, data) {
  const url = api.ADD_OFFER_AFF_SUB_WHITE_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

export function deleteOfferAffSubWhiteList(offerId, affiliateIds) {
  const url = api.DELETE_OFFER_AFF_SUB_WHITE_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: { affiliateIds },
  });
}

export function batchDelOfferAffSubWhiteList(offerId, ids) {
  const url = api.BATCH_DELETE_OFFER_AFF_SUB_WHITE_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

// offer track cap
export function getAffClickCap(offerId, query) {
  const url = api.AFF_CLICK_CAP_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function addAffClickCap(offerId, data) {
  const url = api.ADD_AFF_CLICK_CAP.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

export function deleteAffClickCap(offerId, ids) {
  const url = api.DELETE_AFF_CLICK_CAP.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      ids,
    },
  });
}

// offer aff cap
export function getAffCapList(offerId) {
  const url = api.AFF_CAP_LIST.replace('{offerId}', offerId);
  console.log(url);
  return request({
    url,
    method: 'get',
  });
}

// offer aff permit
export function getOfferPermitList(offerId, affiliateIds) {
  const url = api.OFFER_PERMIT_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    data: {
      affiliateIds,
    },
  });
}

export function addOfferPermit(offerId, data) {
  const url = api.ADD_OFFER_PERMIT.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

export function delOfferPermit(offerId, affiliateIds) {
  const url = api.DELETE_OFFER_PERMIT.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: {
      affiliateIds,
    },
  });
}

// offer status log
export function getStatusLog(offerId, query) {
  const url = api.STATUS_LOG.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function updateOfferTimedTask(data) {
  return request({
    url: api.UPDATE_TIMED_TASK,
    method: 'post',
    data,
  });
}

export function changeOfferCampaignName(data) {
  return request({
    url: api.CHANGE_CAMPAOGN_NAME,
    method: 'post',
    data,
  });
}

// event revenue
export function getEventRevenueList(query) {
  return request({
    url: api.EVENT_REVENUE_LIST,
    method: 'get',
    params: query,
  });
}

export function updateEventRevenue(data) {
  return request({
    url: api.UPDATE_EVENT_REVENUE,
    method: 'post',
    data,
  });
}

export function deleteEventRevenue(query) {
  return request({
    url: api.DELETE_EVENT_REVENUE,
    method: 'delete',
    params: query,
  });
}

// Offer Affiliate Whitelist
export function getAffWhitelist(offerId, query) {
  const url = api.OFFER_AFF_WHITELIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function addAffWhitelist(offerId, data) {
  const url = api.ADD_OFFER_AFF_WHITELIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

export function deleteAffWhitelist(offerId, affiliateIds) {
  const url = api.DELETE_OFFER_AFF_WHITELIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: { affiliateIds },
  });
}

export function batchUpdateAffWhite(data) {
  return request({
    url: api.BATCH_UPDATE_OFFER_AFF_WHITELIST,
    method: 'post',
    data,
  });
}

// Source Offer Clone
export function getSourceCloneList(query) {
  return request({
    url: api.SOURCE_CLONE_LIST,
    method: 'get',
    params: query,
  });
}

export function addSourceClone(data) {
  return request({
    url: api.ADD_SOURCE_CLONE,
    method: 'post',
    data,
  });
}

export function delteSourceClone(query) {
  return request({
    url: api.DELETE_SOURCE_CLONE,
    method: 'delete',
    params: query,
  });
}

//  Affiliate Payout Cap limit
export function getAffPayoutCapList(offerId, query) {
  const url = api.AFFILIATE_PAYOUT_CAP_LIST.replace('{offerId}', offerId);
  return request({
    url,
    method: 'get',
    params: query,
  });
}

export function updateAffPayoutCap(offerId, data) {
  const url = api.ADD_AFFILIATE_PAYOUT_CAP.replace('{offerId}', offerId);
  return request({
    url,
    method: 'post',
    data,
  });
}

export function deleteAffPayoutCap(offerId, ids) {
  const url = api.DELETE_AFFILIATE_PAYOUT_CAP.replace('{offerId}', offerId);
  return request({
    url,
    method: 'delete',
    params: { ids },
  });
}

// Offer Source Trackinges
export function getOfferSourceTrackingesListApi(offerId, data) {
  const url = api.OFFER_SOURCE_TRACK_LIST.replace('{offerId}', offerId);

  return request({
    url,
    method: 'get',
    params: data,
  });
}

export function changeOfferSouceTrackingesApi(offerId, data) {
  const url = api.CHANGE_OFFER_SOURCE_TRACK.replace('{offerId}', offerId);

  return request({
    url,
    method: 'post',
    data,
  });
}
