import request from 'utils/request';

const api = {
  OFFER_LIST: '/api/offer/list',
  OFFER_STATUS_UPDATE: '/api/offer/updateStatus',
  OFFET_TEST_UPDATE: '/api/offer/updateOfferTest',
  APPROVAL_AFFS: '/api/offer/approvalAffs',
  OFFER_MOTIFYS: '/api/offer/motifys',
};

export function getOfferList(query) {
  return request({
    url: api.OFFER_LIST,
    method: 'get',
    params: query,
  });
}

export function updateOfferStatus(offerId) {
  return request({
    url: api.OFFER_STATUS_UPDATE,
    method: 'put',
    data: {
      offerId,
    },
  });
}
export function updateOfferTest(offerId) {
  return request({
    url: api.OFFET_TEST_UPDATE,
    method: 'put',
    data: {
      offerId,
    },
  });
}
export function approvalAfffs(data) {
  return request({
    url: api.APPROVAL_AFFS,
    method: 'put',
    data,
  });
}

export function batchHold(data) {
  return request({
    url: api.OFFER_MOTIFYS,
    method: 'put',
    data,
  });
}
