<template>
  <div class="rankingSearch">
    <div class="title">
      <h2>Ranking & Search</h2>
    </div>
    <div class="bgf0">
      <el-row type="flex">
        <el-col :span="24" style="padding:16px;">
          <el-card shadow="hover" class="pad30">
            <el-form :model="filter" class="form_calss">
              <el-row :gutter="10">
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Source" label-width="1.36rem">
                    <group-select
                      v-model="filter.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                    />
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="sourceName" label-width="100px">
                    <el-input v-model="filter.sourceName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Source Offer" label-width="120px">
                    <el-input
                      v-model="filter.sourceOfferIds"
                      placeholder="source offer id here. eg: 123,456"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Countries" label-width="80px">
                    <el-input v-model="filter.countries" placeholder="countries here"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10" class="martop10">
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Offer ID" label-width="1.36rem">
                    <el-input
                      v-model="filter.offerIds"
                      placeholder="offer id here. eg: 123,456"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Offer Name" label-width="100px">
                    <el-input v-model="filter.offerName" placeholder="offer name here"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Offer Status" label-width="120px">
                    <el-select v-model="filter.status" filterable clearable placeholder="请选择">
                      <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Package Name" label-width="116px">
                    <el-input v-model="filter.prodName" placeholder="package name here"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10" class="martop10">
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Ranking By" label-width="100px">
                    <el-select v-model="filter.ranking" filterable clearable placeholder="请选择">
                      <el-option
                        v-for="item in rankingOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Lowest payout" label-width="110px">
                    <el-input v-model="filter.lowPayout" placeholder="payout value here"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Highest payout" label-width="120px">
                    <el-input
                      v-model="filter.highPayout"
                      placeholder="payout value here"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Direct" label-width="120px">
                    <el-select v-model="filter.direct" clearable placeholder="please add direct...">
                      <el-option label="direct" value="direct"></el-option>
                      <el-option label="on_direct" value="non_direct"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10" class="martop10">
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Market" label-width="100px">
                    <el-select v-model="filter.market" filterable clearable placeholder="请选择">
                      <el-option
                        v-for="item in marketOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="Redirect Status" label-width="110px">
                    <el-select
                      v-model="filter.redirectStatus"
                      filterable
                      clearable
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in rankingStatusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="MaxRedirectCount" label-width="120px">
                    <el-input v-model="filter.maxRedirectCount"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="20" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item label="AfPrt" label-width="120px">
                    <el-input v-model="filter.afPrt" clearable placeholder="af prt here" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :xs="12" :sm="3" :md="3" :lg="3" :xl="3">
                  <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="getOfferList(1)"
                      >Search
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="11" :sm="3" :md="3" :lg="3" :xl="3">
                  <el-form-item>
                    <el-button type="primary" @click="offersVisible = true">ShowIds</el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="10" :sm="3" :md="3" :lg="3" :xl="3">
                  <el-form-item>
                    <el-button type="primary" @click="handlerCopyIds">CopyIds </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="11" :sm="3" :md="3" :lg="3" :xl="3">
                  <!-- 清空按钮 -->
                  <el-form-item>
                    <el-button type="info" @click="setNullInput">SetEmpty</el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="10" :sm="3" :md="3" :lg="3" :xl="3">
                  <el-form-item>
                    <upload :action="doUpload" @handleUploadSuccess="handleUploadSuccess"></upload>
                  </el-form-item>
                </el-col>
                <el-col :xs="11" :sm="3" :md="3" :lg="3" :xl="3">
                  <el-form-item>
                    <el-button type="primary" @click="downloadClick">Download</el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="11" :sm="3" :md="3" :lg="3" :xl="3">
                  <el-form-item>
                    <el-button type="success" style="background-color:#009688;" @click="batchClick"
                      >Batch Hold
                    </el-button>
                  </el-form-item>
                </el-col>
                <el-col :xs="11" :sm="3" :md="3" :lg="3" :xl="3">
                  <el-form-item>
                    <el-button
                      type="success"
                      style="background-color:#009688;"
                      @click="showCloneClick"
                      >{{ filter.showClone ? 'hide' : 'show' }}Clone
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <!-- 选中checkbox弹出 -->
            <el-form :inline="true" :model="checkedBox" v-show="multipleSelection.length !== 0">
              <el-row>
                <el-col :span="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="Affiliate Id" label-width="100px">
                    <group-select
                      size="small"
                      v-model="checkedBox.affiliateIds"
                      :data-source="affiliatesGroupList"
                      multiple
                      clearable
                      reserve-keyword
                      filterable
                      placeholder="请选择"
                      class="marign254"
                      style="width:185px;"
                      hasAll
                      :loading="affiliateLoading"
                      editPlaceholder="affiliate id here:123,456"
                      show-batch
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="DailyCap" label-width="60px">
                    <el-input
                      v-model="checkedBox.dailyCap"
                      placeholder="add cap here"
                      style="width:160px;"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="Payout" label-width="60px">
                    <el-input
                      v-model="checkedBox.payout"
                      placeholder="add payout here"
                      @change="limitPayoutOrPercent(1)"
                      :disabled="payoutFlag"
                      style="width:160px;"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="Payout per" label-width="100px">
                    <el-input
                      v-model="checkedBox.payoutPercent"
                      placeholder="payoutPercent"
                      @change="limitPayoutOrPercent(2)"
                      :disabled="percentFlag"
                      style="width:160px;"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="Adjust" label-width="60px">
                    <el-input
                      v-model="checkedBox.rate"
                      placeholder="add adjust rate"
                      style="width:160px;"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="trafficKpi" label-width="60px">
                    <el-input
                      v-model="checkedBox.trafficKpi"
                      placeholder="add traffic Kpi"
                      style="width:160px;"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label="Description" label-width="80px">
                    <el-input
                      v-model="checkedBox.description"
                      placeholder="add description"
                      style="width:160px;"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" :md="8" :lg="8" :xl="8">
                  <el-form-item label-width="20px" label=" ">
                    <el-button type="primary" style="width:104px" @click="confirmaddToAff"
                      >confirm
                    </el-button>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-card>
          <el-card shadow="hover">
            <el-table
              :data="offerList"
              border
              stripe
              height="70vmin"
              highlight-current-row
              v-loading="listLoading"
              @selection-change="handleSelectionChange"
            >
              <el-table-column
                type="selection"
                width="55"
                align="center"
                :fixed="fullWidth <= 768 ? false : 'left'"
              ></el-table-column>
              <el-table-column
                label="ICON"
                :fixed="fullWidth <= 768 ? false : 'left'"
                align="center"
              >
                <template slot-scope="scope">
                  <a :href="scope.row.previewLink" target="_blank">
                    <img :src="scope.row.icon" class="icon" />
                  </a>
                </template>
              </el-table-column>

              <el-table-column
                label="ProdName"
                prop="prodName"
                align="center"
                :fixed="fullWidth <= 768 ? false : 'left'"
                min-width="140"
              ></el-table-column>
              <el-table-column label="Status" prop="status" align="center">
                <template slot-scope="scope">
                  <span>{{ scope.row.status }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="DurationTime"
                prop="duration"
                align="center"
                min-width="120"
              ></el-table-column>
              <el-table-column label="TrackStatus" min-width="110">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.trackStatus == '0'" type="danger">跳转失败</el-tag>
                  <el-tag v-else-if="scope.row.trackStatus == '1'" type="success">跳转成功</el-tag>
                  <el-tag v-else type="info">请求失败</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="TrackingCount" min-width="130">
                <template slot-scope="scope">
                  <el-tooltip effect="dark" placement="left-start">
                    <div slot="content" v-html="filterDomins(scope.row.domains)"></div>
                    <el-button size="mini" v-if="scope.row.trackCount"
                      >Track {{ scope.row.trackCount }} times
                    </el-button>
                    <el-button size="mini" v-else>UNKNOW</el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column label="OfferId" prop="offerId" align="center" min-width="100">
                <template slot-scope="scope">
                  <span @click="detailClick(scope.row)" class="cor337ab7">{{
                    scope.row.offerId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="OfferName"
                prop="offerName"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="SourceOfferId"
                prop="sourceOfferId"
                align="center"
                min-width="140"
              ></el-table-column>
              <el-table-column label="SourceId" align="center" min-width="100">
                <template slot-scope="scope">
                  <span class="cor337ab7" @click="sourcedetailClick(scope.row)">{{
                    scope.row.sourceId
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="SourceName"
                prop="sourceName"
                align="center"
                min-width="110"
              ></el-table-column>
              <el-table-column
                label="Countries"
                prop="countries"
                align="center"
                min-width="100"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="PayoutType"
                prop="payoutType"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column label="Payout" prop="payout" align="center"></el-table-column>
              <el-table-column label="Revenue" prop="revenue" align="center"></el-table-column>
              <el-table-column
                label="SettleEvent"
                prop="settleEvent"
                align="center"
                min-width="160"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="OfferNameIden"
                prop="offerName"
                align="center"
                min-width="130"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.offerName && scope.row.offerName.indexOf('CPE') !== -1"
                    >CPE</span
                  >
                  <span v-else-if="scope.row.offerName && scope.row.offerName.indexOf('CPA') !== -1"
                    >CPA</span
                  >
                  <span v-else></span>
                </template>
              </el-table-column>
              <el-table-column
                label="Platforms"
                prop="platforms"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column label="TaskStatus" prop="openStatus" align="center" min-width="100">
                <template slot-scope="scope">
                  <span>{{ scope.row.openStatus }}</span>
                </template>
              </el-table-column>
              <el-table-column label="TrafficTag" prop="trafficTag" align="center" min-width="150">
                <template slot-scope="scope" v-if="scope.row.trafficTag">
                  <el-tag type="success" v-for="item in scope.row.trafficTag.split(',')" :key="item"
                    >{{ item }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="Direct"
                prop="direct"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="AfPrt"
                prop="afPrt"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="OpenTime"
                prop="openTime"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column
                label="CloseTime"
                prop="closeTime"
                align="center"
                min-width="100"
              ></el-table-column>
              <el-table-column label="Market" prop="market" align="center"></el-table-column>
              <el-table-column label="Option" align="center" min-width="120" fixed="right">
                <template slot-scope="scope">
                  <el-dropdown>
                    <el-button type="primary">
                      More<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <template v-if="scope.row.status == 'active'">
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="success"
                            style="width:100%"
                            @click="change_Status(scope.row.offerId)"
                            >Hold
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="primary"
                            style="width:100%"
                            @click="copy_offer(scope.column, scope.row)"
                            >Copy
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="primary"
                            style="width:100%"
                            @click="copyOfferInfo(scope.row)"
                            >Cut
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="danger"
                            style="width:100%"
                            @click="update_offer(scope.row)"
                            >Update
                          </el-button>
                        </el-dropdown-item>
                      </template>
                      <template v-else-if="scope.row.status == 'hold'">
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="success"
                            @click="change_Status(scope.row.offerId)"
                            >Pending
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="primary"
                            style="width:100%"
                            @click="copyOfferInfo(scope.row)"
                            >Cut
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="primary"
                            style="width:100%"
                            @click="copy_offer(scope.column, scope.row)"
                            >Copy
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button size="mini" type="danger" @click="update_offer(scope.row)"
                            >Update
                          </el-button>
                        </el-dropdown-item>
                      </template>
                      <template v-else>
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="success"
                            style="width:100%"
                            @click="change_Status(scope.row.offerId)"
                            >Active
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="primary"
                            style="width:100%"
                            @click="copyOfferInfo(scope.row)"
                            >Cut
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button
                            size="mini"
                            type="primary"
                            @click="copy_offer(scope.column, scope.row)"
                            >Copy
                          </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item>
                          <el-button size="mini" type="danger" @click="update_offer(scope.row)"
                            >Update
                          </el-button>
                        </el-dropdown-item>
                      </template>
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              class="block pagePanel"
              :pageTotal="total"
              @handleSizeChange="handleSizeChange"
              @handleCurrentChange="handleCurrentChange"
              :pageSize="pageParam.pageSize"
              :currentPage="pageParam.page"
            ></pagination>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <el-dialog title="offerIds" width="30%" :visible.sync="offersVisible">
      <span style="font-size:16px;">{{ offers }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="offersVisible = false">确定</el-button>
      </span>
    </el-dialog>

    <!-- 批单提示框 -->
    <el-dialog title="批单信息" :visible.sync="toAffVisible">
      <el-table :data="tipsData" stripe border>
        <el-table-column label="AffiliateId" prop="affiliateId" align="center"></el-table-column>
        <el-table-column label="Type & Msg" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.type">{{ scope.row.msg }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="OfferIds" prop="offerIds" align="center"></el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="Update Offer Payout" :visible.sync="payoutSettingVisible" width="500px">
      <el-form :model="offerPayout" label-position="left">
        <el-form-item label="Payout">
          <el-input
            v-model="offerPayout.payout"
            :disabled="offerPayout.controlType !== 'api'"
          ></el-input>
        </el-form-item>
        <el-form-item label="revenue">
          <el-input v-model="offerPayout.revenue"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="payoutSettingVisible = false">返回</el-button>
        <el-button type="primary" @click="offerPayoutChange">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import { updateOfferPayout } from 'api/offer/detail';
  import {
    approvalAfffs,
    batchHold,
    getOfferList,
    updateOfferStatus,
  } from 'api/product/rankSearch';
  import { confirmTraffic } from '@/api/affiliate/detail';
  import { getAllSourceList } from 'api/source/index';

  import Upload from '@/components/FileUpload';
  import Pagination from '@/components/pagination';
  import GroupSelect from '@/components/GroupSelect';

  import qs from 'querystring';
  import { myMixin } from '@/mixins/mixins.js';
  import optionData from '@/assets/js/optionData';
  import { mapActions, mapState } from 'vuex';
  import { cloneDeep } from 'lodash-es';

  var clickTimer = null;
  export default {
    mixins: [myMixin],
    components: {
      Pagination,
      Upload,
      GroupSelect,
    },
    data() {
      return {
        carriersGroup: [],
        listLoading: false,
        payoutFlag: false,
        percentFlag: false,
        toAffVisible: false,
        tipsData: [],
        doUpload: '/trans/offer/downloadTopOffers',
        dataPram: null,
        total: 0,
        updateVisible: false,
        offersVisible: false,
        copyArr: [],
        updateOffer: {
          offerId: null,
          payout: null,
          revenue: null,
        },
        offers: '',
        offerList: [],
        fullWidth: document.documentElement.clientWidth,
        rankingStatusOptions: [
          {
            label: 'ALL',
            value: null,
          },
          {
            label: '跳转失败',
            value: 0,
          },
          {
            label: '跳转成功',
            value: 1,
          },
          {
            label: '请求失败',
            value: -1,
          },
        ],
        carriers: optionData.carriers,
        rankingOptions: [
          {
            label: 'Default',
            value: '',
          },
          {
            label: 'RedirectSuccess',
            value: 'RedirectSuccess',
          },
          {
            label: 'RedirectTime',
            value: 'RedirectTime',
          },
          {
            label: 'Payout',
            value: 'Payout',
          },
          {
            label: 'convs',
            value: 'convs',
          },
        ],
        statusOptions: [
          {
            label: 'ACTIVE',
            value: 'ACTIVE',
          },
          {
            label: 'PENDING',
            value: 'PENDING',
          },
          {
            label: 'PAUSED',
            value: 'PAUSED',
          },
          {
            label: 'HOLD',
            value: 'HOLD',
          },
          {
            label: 'BLOCK',
            value: 'BLOCK',
          },
          {
            label: 'STOP',
            value: 'STOP',
          },
          {
            label: 'ALL',
            value: 'ALL',
          },
        ],
        marketOptions: [
          {
            label: 'ALL',
            value: null,
          },
          {
            label: 'Google Play',
            value: 'GP',
          },
          {
            label: 'App Store',
            value: 'ITUNES',
          },
        ],
        filter: {
          sourceIds: [],
          offerIds: '',
          sourceOfferIds: '',
          status: 'ACTIVE',
          sourceName: '',
          offerName: '',
          prodName: '',
          market: '',
          lowPayout: '',
          highPayout: '',
          countries: '',
          ranking: '',
          redirectStatus: null,
          maxRedirectCount: '',
          showClone: 1,
          direct: '',
          afPrt: '',
        },
        pageParam: {
          page: 1,
          pageSize: 20,
        },
        checkedBox: {},
        multipleSelection: [],
        loading: false,
        payoutSettingVisible: false,
        offerPayout: {},
        sourcesList: [],
        sourceLoading: false,
      };
    },
    computed: {
      ...mapState('affiliate', {
        affiliatesGroupList: (state) => state.affiliatesGroupList,
        affiliateLoading: (state) => state.affiliateLoading,
      }),
    },
    mounted() {
      this.payoutFlag = false;
      this.percentFlag = false;
      this.getSourceList();
      this.getAffiliateList();
      this.getSourceIdsList();
      for (var key in this.carriers) {
        this.carriersGroup.push({
          label: key,
          children: this.carriers[key],
        });
      }
    },
    methods: {
      ...mapActions('affiliate', ['getAffiliateList']),
      ...mapActions('source', ['getSourceIdsList']),
      handlerCopyIds() {
        const ids = this.copyArr.toString();
        this.$copyText(ids)
          .then((e) => {
            this.$message.success('Copy Succee');
            console.log(e);
          })
          .catch((e) => {
            if (ids) {
              this.$message.error('Copy the failure');
            } else {
              this.$message.error('Please select the list of ids to be copied first');
            }
            console.log(e);
          });
      },
      filterDomins(domains) {
        var content = '';
        if (domains !== '' && domains !== null && domains !== undefined) {
          domains.split(',').forEach((item, i) => {
            content += i + ' : ' + item + '</br>';
          });
        }
        return content;
      },
      getOfferList(curPage) {
        if (curPage) {
          this.pageParam.page = curPage;
        }
        this.dataPram = this.copy(this.filter);
        if (Array.isArray(this.dataPram.sourceIds) && this.dataPram.sourceIds.length > 0) {
          this.dataPram.sourceIds = this.dataPram.sourceIds.join();
        } else {
          delete this.dataPram.sourceIds;
        }
        for (const key in this.dataPram) {
          if (this.dataPram[key] === '') {
            delete this.dataPram[key];
          }
        }
        this.offerListApi();
      },
      offerListApi() {
        this.listLoading = true;
        let param = {
          ...this.dataPram,
          page: this.pageParam.page,
          pageSize: this.pageParam.pageSize,
        };
        getOfferList(param)
          .then((response) => {
            this.listLoading = false;
            if (response.code == 200) {
              this.offerList = response.result;
              this.offers = this.offerList?.map((item) => item.offerId).join();
              this.total = response.total;
            } else {
              this.$message.error(response.message);
              this.offerList = [];
              this.total = 0;
              this.offers = null;
            }
          })
          .catch(() => {
            this.offerList = [];
            this.total = 0;
            this.offers = null;
            this.listLoading = false;
          });
      },
      handleSizeChange(size) {
        this.pageParam.pageSize = size;
        this.handleCurrentChange(1);
      },
      async handleCurrentChange(currentPage) {
        this.pageParam.page = currentPage;
        this.getOfferList();
      },
      handleUploadSuccess() {},
      change_Status(id) {
        updateOfferStatus(id).then((response) => {
          if (response.code == 200) {
            this.$message({
              message: 'Success',
              type: 'success',
            });
            this.getOfferList();
          } else {
            this.$message.error('failed,params is error!! ');
          }
        });
      },
      update_offer(row) {
        this.offerPayout = cloneDeep(row);
        this.payoutSettingVisible = true;
      },
      copy_offer(column, row) {
        let copyOffer = {};
        if (column.label == 'Option') {
          copyOffer = this.copy(row);
          const { href } = this.$router.resolve({
            path: '/offer/add',
          });
          sessionStorage.setItem('copyOffer', JSON.stringify(copyOffer));
          window.open(href, '_blank');
        }
      },
      offerPayoutChange() {
        const param = {
          payout: this.offerPayout.payout,
          revenue: this.offerPayout.revenue,
          offerId: this.offerPayout.offerId,
        };
        updateOfferPayout(param).then((res) => {
          if (res.code === 200) {
            this.$message.success('修改成功');
            this.payoutSettingVisible = false;
            this.getOfferList();
          } else {
            this.$message.error(res.message);
          }
        });
      },
      detailClick(row) {
        if (clickTimer) {
          window.clearTimeout(clickTimer);
          clickTimer = null;
        }
        var that = this;
        clickTimer = window.setTimeout(function() {
          that.$nextTick(() => {
            const { href } = that.$router.resolve({
              path: '/offer/detail',
              query: {
                offerId: row.offerId,
              },
            });
            window.open(href, '_blank');
          });
        }, 300);
      },
      sourcedetailClick(row) {
        const { href } = this.$router.resolve({
          path: '/source/detail',
          query: {
            sourceId: row.sourceId,
          },
        });
        window.open(href, '_blank');
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;

        this.copyArr = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          this.copyArr.push(this.multipleSelection[i].offerId);
        }
      },
      downloadClick() {
        let param = {};
        for (const key in this.filter) {
          if (!this.filter[key] && this.filter[key] !== 0) {
            continue;
          }
          console.log(this.filter[key], '&&', key, !this.filter[key], this.filter[key] !== 0);
          if (Array.isArray(this.filter[key])) {
            if (this.filter[key].length === 0) {
              continue;
            }
            param[key] = this.filter[key].toString();
          } else {
            param[key] = this.filter[key];
          }
        }
        let strParam = qs.stringify(param);
        window.open(`/api/offer/downloadTopOffers?${strParam}`, '_blank');
      },
      batchClick() {
        if (this.multipleSelection.length == 0) {
          this.$message.error('do not choose offer!');
          return;
        }
        var offerIds = new Array(this.multipleSelection.length);
        for (var i = 0; i < this.multipleSelection.length; i++) {
          offerIds[i] = this.multipleSelection[i].offerId;
        }

        this.$confirm('Are you want to Hold these Offers？', 'Message', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
        })
          .then(() => {
            let param = {
              offerIds: offerIds,
            };
            batchHold(param).then((response) => {
              if (response.code === 200) {
                this.$message({
                  message: 'Success',
                  type: 'success',
                });
                this.getOfferList();
              } else {
                this.$message.error('failed,params is error!! ');
              }
            });
          })
          .catch(() => {
            this.$message({
              message: 'You have cancel',
              type: 'info',
            });
          });
      },
      confirmaddToAff() {
        const offerIds = new Array(this.multipleSelection.length);
        for (let i = 0; i < this.multipleSelection.length; i++) {
          offerIds[i] = this.multipleSelection[i].offerId;
        }

        offerIds.forEach((item) => {
          this.offerList.find((d) => {
            if (d.offerId == item) {
              if (this.checkedBox.payout > d.payout * 2) {
                this.$message.error('payout setting is so high');
                return;
              }
            }
          });
        });
        const param = {
          ...this.checkedBox,
        };

        param['offerIds'] = offerIds;
        const confirmText = [
          'You choose offer white list:',
          offerIds.toString(),
          'For affiliate:',
          param.affiliateIds + ',',
        ];
        const newDatas = [];
        const h = this.$createElement;

        for (const i in confirmText) {
          newDatas.push(h('p', { style: 'word-wrap: break-word' }, confirmText[i]));
        }
        confirmTraffic(offerIds).then((resp) => {
          if (resp.code === 200) {
            if (resp.result && resp.result.tips) {
              newDatas.push(h('p', { style: 'color:red;font-weight:bolder' }, resp.result.tips));
            }
            if (resp.result && resp.result.source) {
              newDatas.push(
                h(
                  'p',
                  { style: 'color:red;font-weight:bolder' },
                  `SourceIds:${resp.result.source.join(',')}`
                )
              );
            }

            this.$confirm('Tip', {
              message: h(
                'div',
                {
                  style: 'max-height:500px;overflow-y:auto',
                },
                newDatas
              ),
              confirmButtonText: 'Confirm',
              cancelButtonText: 'Cancel',
            })
              .then(() => {
                approvalAfffs(param).then((response) => {
                  if (response.code == 200) {
                    this.$message({
                      message: 'Success',
                      type: 'success',
                    });

                    this.tipsData = response.result || [];
                    if (this.tipsData.length > 0) {
                      for (var i = 0; i < this.tipsData.length; i++) {
                        let offerids = this.tipsData[i].offerIds.toString();
                        this.tipsData[i].offerIds = offerids;
                      }
                    }
                  } else {
                    this.$message.error(response.message);
                  }
                });
                this.toAffVisible = true;
              })
              .catch(() => {
                this.$message({
                  message: 'You have cancel',
                  type: 'info',
                });
              });
          }
        });
      },
      limitPayoutOrPercent(flag) {
        if (flag == 1 && this.checkedBox.payout != null && this.checkedBox.payout != '') {
          this.payoutFlag = false;
          this.percentFlag = true;
        } else if (
          flag == 2 &&
          this.checkedBox.payoutPercent != null &&
          this.checkedBox.payoutPercent != ''
        ) {
          this.payoutFlag = true;
          this.percentFlag = false;
        } else {
          this.payoutFlag = false;
          this.percentFlag = false;
        }
      },
      setNullInput() {
        this.filter.sourceIds = [];
        this.filter.offerIds = '';
        this.filter.sourceOfferIds = '';
        this.filter.status = 'ACTIVE';
        this.filter.sourceName = '';
        this.filter.offerName = '';
        this.filter.prodName = '';
        this.filter.market = '';
        this.filter.lowPayout = '';
        this.filter.highPayout = '';
        this.filter.countries = '';
        this.filter.ranking = '';
        this.filter.redirectStatus = '';
        this.filter.maxRedirectCount = '';
      },
      /**
       * 复制信息到剪贴板
       */
      copyOfferInfo(offer) {
        const copyText = `
          offerid:${offer.offerId || ''}
          offername:${offer.offerName || ''}
          preview link:${offer.previewLink || ''}
          description:${offer.description || ''}
          payout:${offer.payout || ''}
          countries:${offer.payoutType || ''}
          tracking link:${offer.trackingLink || ''}`;
        this.$copyText(copyText)
          .then((e) => {
            this.$message.success('Copy Succee');
            console.log(e);
          })
          .catch((e) => {
            this.$message.error('Copy the failure');
            console.log(e);
          });
      },
      async getSourceList() {
        this.sourceLoading = true;
        const response = await getAllSourceList();

        let sourcesList = [];
        if (response.code == 200) {
          let sourceIds = response.result;
          const CPIGorups = {
            id: 1,
            checked: false,
            label: 'CPI',
            children: [],
          };

          const CPAGorups = {
            id: 2,
            checked: false,
            label: 'CPA',
            children: [],
          };
          const SmartLinkGroups = {
            id: 3,
            checked: false,
            label: 'SmartLink',
            children: [],
          };

          const CPLGroups = {
            id: 4,
            checked: false,
            label: 'CPL',
            children: [],
          };

          const PausedPending = {
            id: 5,
            checked: false,
            label: 'Paused&Pending',
            children: [],
          };
          sourceIds.forEach((item) => {
            if (item.enable !== 'ENABLE') {
              PausedPending.children.push({
                id: item.id,
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            } else if (item.payoutType == 'CPI') {
              CPIGorups.children.push({
                id: item.id,
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            } else if (item.payoutType == 'CPA') {
              CPAGorups.children.push({
                id: item.id,
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            } else if (item.payoutType == 'CPL') {
              CPLGroups.children.push({
                id: item.id,
                value: item.id,
                label: `${item.id} - ${item.company}`,
              });
            }
          });
          sourcesList.push(CPIGorups, CPAGorups, SmartLinkGroups, CPLGroups, PausedPending);
          this.sourcesList = sourcesList;
        }
        this.sourceLoading = false;
      },
      showCloneClick() {
        this.filter.showClone = +!this.filter.showClone;
        this.getOfferList(1);
      },
    },
  };
</script>
<style lang="scss"></style>

<style lang="scss" scoped>
  .icon {
    width: 40px;
    height: 40px;
  }

  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .upload {
    display: inline-block;
  }

  .martop10 {
    margin-top: 10px;
  }
</style>
